import * as yup from 'yup';
import { path } from 'ramda';


import validationLocale from './constants/validationLocale';
import { includes } from 'ramda';
yup.setLocale(validationLocale);

export const OTHER_VALUE = 'Другое';

yup.addMethod(yup.string, 'checkOther', function ( message = 'Необходимо ввести текст в поле "Другое"') {
    return this.test('checkOther', message, function (value, context) {
        return value === OTHER_VALUE ? !!path(['parent', `${context.path}_other`], context) : true;
    });
});

yup.addMethod(yup.array, 'checkOtherArray', function (message = 'Необходимо ввести текст в поле "Другое"') {
    return this.test('checkOther', message, function (value, context) {
        return includes(OTHER_VALUE, value || []) ? !!path(['parent', `${context.path}_other`], context) : true;
    });
});
