const validationLocale = {
    mixed: {
        required: 'Нет ответа на обязательный вопрос'
    },
    string: {
        email: 'Введите верный email'
    }
};

export default validationLocale;
