import { without, append, includes } from "ramda";
import cx from 'classnames';

import withFieldWrapper from "./withFieldWrapper";
import { Fragment } from "react";
import { OTHER_VALUE } from "../../yup";

const NOTHING_VALUE = 'Ничего из вышеперечисленного';

const Checkboxes = ({ input, onChange, options = [], withOther, withNothing, max, onChangeOther, otherValue }) => {
    const onChangeValue = e => {
        const current = input.value || [];

        if (includes(NOTHING_VALUE, current)) {
            return;
        }

        if (max && current.length >= max && e.target.checked) {
            return;
        }

        if (includes(e.target.value, current)) {
            const items = without([e.target.value], input.value);

            onChange(items.length ? items : null);
        } else {
            onChange(append(e.target.value, current))
        }
    }

    const onChangeOtherCheckbox = e => {
        if (!e.target.checked) {
            onChangeOther(null);
        }

        onChangeValue(e);
    }

    const onChangeNothing = e => {
        onChangeOther(null);
        onChange(e.target.checked ? [NOTHING_VALUE] : null);
    }

    const onChangeOtherValue = e => onChangeOther(e.target.value);

    return <div className="question__wrapper">
	    <div className="question__panel">
            <div className="question-list">
                { options.map(value =>
                    <label key={`${input.name}-${value}`} className={cx("question-list__item", { active: includes(value, input.value || []) })}>
                        <input value={value} type="checkbox" checked={includes(value, input.value || [])} onChange={onChangeValue} />
                        <span className="question-list__item-text">{ value }</span>
                    </label>
                )}
            </div>
    
            <div className="question-list">
                { withOther &&
                    <label className={cx("question-list__item question-list__item--other", { active: includes(OTHER_VALUE, input.value || []) })}>
                        <input value="Другое" type="checkbox" checked={includes(OTHER_VALUE, input.value || [])} onChange={onChangeOtherCheckbox} />
                        <span className="question-list__item-text">{ OTHER_VALUE }</span>
                        { includes(OTHER_VALUE, input.value || []) &&
                            <Fragment>
                                <textarea
                                    value={otherValue || ''}
                                    onChange={onChangeOtherValue}
                                    cols={10}
                                    rows={3}
                                    className="form-control"
                                    maxLength={2000} />
                                <div className="question__hint">Напишите ваш вариант ответа</div>
                            </Fragment>
                        }
                    </label>
                }
        
                { withNothing &&
                    <label className={cx("question-list__item question-list__item--nothing", { active: includes(NOTHING_VALUE, input.value || []) })}>
                        <input
                            value={NOTHING_VALUE}
                            type="checkbox"
                            checked={includes(NOTHING_VALUE, input.value || [])}
                            onChange={onChangeNothing} />
                        <span className="question-list__item-text">{ NOTHING_VALUE }</span>
                    </label>
                }
            </div>
        </div>
    </div>;
}

export default withFieldWrapper(Checkboxes);
