import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';

import SurveyForm from "./SurveyForm";

const N8N_WEBHOOK = 'https://n8n.planka.ancor.ru/webhook/b012956f-fe40-473d-b2ec-aac46a68de71';

function App() {
    let [searchParams, setSearchParams] = useSearchParams();
    const isSuccessPage = searchParams.get('success') === '1';

    const onSubmit = values => {
        fetch(N8N_WEBHOOK, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values),
        })
            .then(response => response.json())
            .then(() => {
                setSearchParams({ success: 1 });
            })
            .catch(() => {
                toast.error('Ошибка отправки формы');
            });
    }

    return (
        <div className='survey-fill survey-fill--custom survey-fill--type-direct survey-fill--process-survey survey-fill--page-questions'>
            <div className="survey">
                <div className="container">
                    { isSuccessPage ?
                        <div className='success-block'>
                            <h1>Благодарим за регистрацию!</h1>
                            <p>Спасибо, что уделили время для заполнения анкеты. Мы очень ценим вашу готовность участвовать в наших исследованиях. Чтобы выразить нашу благодарность, мы подготовили для вас небольшой подарок – полезный материал по управлению стрессом по время поиска работы.</p>
                            <p><strong>Ваш подарок: <a href='files/Управление стрессом_Edit.pdf' download>Скачать PDF</a></strong></p>
                            <p>Если у вас есть вопросы или предложения, не стесняйтесь связаться с нами. Мы всегда рады вашим отзывам!</p>
                        </div> :
                        <div className="survey-page survey-page--questions">
                            <SurveyForm
                                onSubmit={onSubmit} />
                        </div>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default App;
