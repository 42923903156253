import cx from 'classnames';

import withFieldWrapper from "./withFieldWrapper";
import { Fragment } from 'react';
import { OTHER_VALUE } from '../../yup';

const RadioButtons = ({ input, onChange, options = [], withOther, onChangeOther, otherValue }) => {
    const onChangeValue = e => {
        if (e.target.value !== OTHER_VALUE) {
            onChangeOther(null);
        }

        onChange(e.target.value);
    }

    const onChangeOtherValue = e => onChangeOther(e.target.value);

    return <div className="question__wrapper">
	    <div className="question__panel">
            <div className="question-list">
                { options.map(value =>
                    <label key={`${input.name}-${value}`} className={cx("question-list__item", { active: value === input.value })}>
                        <input value={value} type="radio" checked={value === input.value} onChange={onChangeValue} />
                        <span className="question-list__item-text">{ value }</span>
                    </label>
                )}
                { withOther &&
                    <div className="question-list">
                        <label className={cx("question-list__item question-list__item--other", { active: input.value === OTHER_VALUE })}>
                            <input value="Другое" type="radio" checked={input.value === OTHER_VALUE} onChange={onChangeValue} />
                            <span className="question-list__item-text">{ OTHER_VALUE }</span>
                            { input.value === OTHER_VALUE &&
                                <Fragment>
                                    <textarea
                                        value={otherValue || ''}
                                        onChange={onChangeOtherValue}
                                        cols={10}
                                        rows={3}
                                        className="form-control"
                                        maxLength={2000}
                                    />
                                    <div className="question__hint">Напишите ваш вариант ответа</div>
                                </Fragment>
                            }
                        </label>
                    </div>
                }
            </div>
        </div>
    </div>;
}

export default withFieldWrapper(RadioButtons);
