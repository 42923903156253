import { Form, Field } from 'react-final-form';
import { assocPath, is } from 'ramda';
import * as yup from 'yup';

import Input from './formComponents/Input';
import { COUNTRIES, GENDER, AGE, EDUCATION, EMPLOYMENT, EDUCATION_TYPES, ORGANIZATION_TYPES, POSITION_TYPES, REGIONS, CITIES } from '../constants/form';
import Select from './formComponents/Select';
import RadioButtons from './formComponents/RadioButtons';
import Checkboxes from './formComponents/Checkboxes';
import Checkbox from './formComponents/Checkbox';
import ListenerField from './formComponents/ListenerField';
import { Fragment } from 'react';

const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    country: yup.string().checkOther().required(),
    region: yup.string().when('country', {
        is: value => value === 'Россия',
        then: schema => schema.checkOther().required(),
        otherwise: schema => schema,
    }),
    city: yup.string().when('country', {
        is: value => value === 'Россия',
        then: schema => schema.when('region', {
            is: value => value !== REGIONS[0] && value !== REGIONS[1],
            then: schema => schema.checkOther().required(),
            otherwise: schema => schema,
        }),
        otherwise: schema => schema.required(),
    }),
    gender: yup.string().required(),
    age: yup.string().required(),
    education: yup.string().checkOther().required(),
    employment: yup.string().checkOther().required(),
    educationType: yup.string().when('employment', {
        is: value => value === EMPLOYMENT[1] || value === EMPLOYMENT[2],
        then: schema => schema.checkOther().required(),
        otherwise: schema => schema,
    }),
    organizationType: yup.string().when('employment', {
        is: value => value === EMPLOYMENT[0] || value === EMPLOYMENT[1],
        then: schema => schema.checkOther().required(),
        otherwise: schema => schema,
    }),
    wantedOrganizationType: yup.array().checkOtherArray().required(),
    positionLevel: yup.string().when('employment', {
        is: value => value === EMPLOYMENT[0] || value === EMPLOYMENT[1],
        then: schema => schema.checkOther().required(),
        otherwise: schema => schema,
    }),
    wantedPositionLevel: yup.string().checkOther().required(),
    currentPosition: yup.string().when('employment', {
        is: value => value === EMPLOYMENT[0] || value === EMPLOYMENT[1],
        then: schema => schema.required(),
        otherwise: schema => schema,
    }),
    wantedPosition: yup.string().required(),
    agreement: yup.boolean().required()
});

const SurveyForm = props => {
    const validate = values => {
        const schema = validationSchema;

        if (!schema) {
            return {};
        }

        try {
            schema.validateSync(values, { abortEarly: false });
        } catch (e) {
            return (e.inner || []).reduce((errors, error) => {
                let path = error.path.split(/\.|\].|\[/).map(p => isNaN(Number(p.replace(/\[|\]/g, ''))) ? p : Number(p.replace(/\[|\]/g, '')));
                path = error.params.additionalPath ? path.concat(error.params.additionalPath) : path;
                return assocPath(is(Array, error.value) ? path : path, error.message, errors);
            }, {});
        }
    }

    return <Form
        onSubmit={props.onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className='survey__questions'>
                <Field
                    name='email'
                    htmlType='email'
                    component={Input}
                    label='Укажите, пожалуйста, вашу почту:'
                    placeholder='Введите E-mail'
                    shortInput
                    required />
                <Field
                    name='country'
                    component={Select}
                    label='В какой стране вы сейчас проживаете?'
                    options={COUNTRIES}
                    withOther
                    required />
                <ListenerField listenFieldName='country'>
                    { ({ country }) => country === 'Россия' ?
                        <Fragment>
                            <Field
                                name='region'
                                component={Select}
                                label={<span>
                                    <span>В каком регионе вы сейчас проживаете?</span>
                                    <br />
                                    <i>Если в выпадающем списке нет вашего региона, введите "другое" и впишите свой регион вручную</i>
                                </span>}
                                options={REGIONS}
                                withOther
                                required />
                            <ListenerField listenFieldName='region'>
                                { ({ region }) => (region !== REGIONS[0] && region !== REGIONS[1] && region) &&
                                    <Field
                                        name='city'
                                        component={Select}
                                        label={<span>
                                            <span>В каком населенном пункте вы сейчас проживаете?</span>
                                            <br />
                                            <i>Если в выпадающем списке нет вашего населенного пункта, введите "другое" и впишите свой населенный пункт вручную</i>
                                        </span>}
                                        options={CITIES}
                                        withOther
                                        required />
                                }
                            </ListenerField>
                        </Fragment> :
                        <Field
                            name='city'
                            component={Input}
                            label='В каком населенном пункте вы сейчас проживаете?'
                            required />
                    }
                </ListenerField>
                <Field
                    name='gender'
                    component={RadioButtons}
                    label='Укажите, пожалуйста, ваш пол:'
                    options={GENDER}
                    required />
                <Field
                    name='age'
                    component={RadioButtons}
                    label='Укажите, пожалуйста, ваш возраст:'
                    options={AGE}
                    required />
                <Field
                    name='education'
                    component={RadioButtons}
                    label='Укажите, пожалуйста, ваш уровень образования:'
                    options={EDUCATION}
                    withOther
                    required />
                <Field
                    name='employment'
                    component={RadioButtons}
                    label='Как бы вы могли охарактеризовать свой статус занятости?'
                    options={EMPLOYMENT}
                    withOther
                    required />
                <ListenerField listenFieldName='employment'>
                    { ({ employment }) => (employment === EMPLOYMENT[1] || employment === EMPLOYMENT[2]) &&
                        <Field
                            name='educationType'
                            component={RadioButtons}
                            label='Студентом какого направления подготовки вы являетесь?'
                            options={EDUCATION_TYPES}
                            withOther
                            required />
                    }
                </ListenerField>
                <ListenerField listenFieldName='employment'>
                    { ({ employment }) => (employment === EMPLOYMENT[0] || employment === EMPLOYMENT[1]) &&
                        <Field
                            name='organizationType'
                            component={RadioButtons}
                            label='Уточните, пожалуйста, к какой отрасли относится организация, в которой вы работаете?'
                            options={ORGANIZATION_TYPES}
                            withOther
                            required />
                    }
                </ListenerField>
                <Field
                    name='wantedOrganizationType'
                    component={Checkboxes}
                    label='Если бы вы искали работу, какую бы отрасль вы рассмотрели?'
                    hint='Необходимо выбрать не более 3 вариантов.'
                    options={ORGANIZATION_TYPES}
                    max={3}
                    withNothing
                    withOther
                    required />
                <ListenerField listenFieldName='employment'>
                    { ({ employment }) => (employment === EMPLOYMENT[0] || employment === EMPLOYMENT[1]) &&
                        <Field
                            name='positionLevel'
                            component={RadioButtons}
                            label='Укажите, пожалуйста, ваш уровень должности:'
                            options={POSITION_TYPES}
                            withOther
                            required />
                    }
                </ListenerField>
                <Field
                    name='wantedPositionLevel'
                    component={RadioButtons}
                    label='Если бы вы искали работу, то какой уровень должности хотели бы занимать?'
                    options={POSITION_TYPES}
                    withOther
                    required />
                <ListenerField listenFieldName='employment'>
                    { ({ employment }) => (employment === EMPLOYMENT[0] || employment === EMPLOYMENT[1]) &&
                        <Field
                            name='currentPosition'
                            component={Input}
                            label='Укажите, пожалуйста, вашу должность:'
                            required />
                    }
                </ListenerField>
                <Field
                    name='wantedPosition'
                    component={Input}
                    label='Если бы вы искали работу, то какую должность хотели бы занимать?'
                    required />
                <ListenerField listenFieldName='employment'>
                    { ({ employment }) => (employment === EMPLOYMENT[0] || employment === EMPLOYMENT[1]) &&
                        <Field
                            name='responsibilities'
                            component={Input}
                            label='Укажите, пожалуйста, свои рабочие обязанности:' />
                    }
                </ListenerField>
                <Field
                    name='wantedResponsibilities'
                    component={Input}
                    label='Если бы вы искали работу, какие рабочие обязанности хотели бы выполнять?' />
                <Field
                    name='agreement'
                    component={Checkbox}
                    label='Обработка персональных данных'
                    comment={<span>Отправляя анкету, даю <a target="_blank" rel="noreferrer" href="https://ancor.ru/upload/other/sopd.pdf"><strong>согласие</strong></a> на обработку персональных данных содержащихся в ней.</span>}
                    text='Подтверждаю'
                    boldLabel
                    required />
                <Field
                    name='mailing'
                    component={Checkbox}
                    comment={<span>Также даю свое <a target="_blank" rel="noreferrer" href="https://ancor.ru/upload/other/sirr.pdf"><strong>согласие</strong></a> на получение рассылки рекламно-информационных материалов.</span>}
                    text='Подтверждаю' />
                <div className="survey__buttons ">
                    <button
                        type="submit"
                        data-role="next-btn"
                        className="button button--filled">
                        Завершить
                    </button>
                </div>
            </form>
        )}
    />;
}

export default SurveyForm;
