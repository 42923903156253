import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import './styles/bootstrap.min.css';
import './styles/index.css';

import './yup';

import App from './components/App';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
