import { Fragment, useState } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { split, assocPath } from 'ramda';

const ListenerField = props => {
    const [state, setState] = useState({});
    const { listenFieldName } = props;

    const setValue = (name, value) => {
        setState(prevState => assocPath(split('.', name), value, prevState));
    }

    return (
        <Fragment>
            { Array.isArray(listenFieldName) ? (
                listenFieldName.map(name => (
                    <OnChange key={name} name={name}>
                        { value => setValue(name, value)}
                    </OnChange>
                ))
            ) : (
                <OnChange name={listenFieldName}>
                    { value => setValue(listenFieldName, value)}
                </OnChange>
            )}
            { props.children(state) }
        </Fragment>
    );
}

export default ListenerField;
