import cx from 'classnames';

import withFieldWrapper from "./withFieldWrapper";

const Input = ({ htmlType = 'text', input: { value }, onChange, placeholder, id, autoComplete, max, min, shortInput }) => {
    const onChangeValue = e => onChange(htmlType === 'number' ?
        (e.target.value && !isNaN(e.target.value) ? Number(e.target.value) : null) :
        e.target.value
    );

    return <div className="question__wrapper">
        <div className={cx({ "row": shortInput })}>
            <div className={cx({ "col-sm-6": shortInput })}>
                <input
                    className="form-control"
                    id={id}
                    type={htmlType}
                    value={value}
                    onChange={onChangeValue}
                    placeholder={placeholder || ' '}
                    autoComplete={autoComplete}
                    min={min}
                    max={max}
                    inputMode={htmlType === 'number' ? 'decimal' : null}
                />
            </div>
        </div>
    </div>;
}

export default withFieldWrapper(Input);
