import { useEffect } from 'react';
import cx from 'classnames';
import { useForm, useFormState } from 'react-final-form';
import { path } from 'ramda';

const withFieldWrapper = WrappedComponent => {
    const FieldWrapper = props => {
        const form = useForm();
        const formState = useFormState();

        const onChange = (value, data) => {
            const { input, onChange, parse } = props;
            const val = parse ? parse(value) : value;

            input.onChange(val);
            onChange && onChange(val, data);
        }

        const onChangeOther = value => {
            form.change(`${props.input.name}_other`, value);
        }

        useEffect(() => {
            return () => {
                if (!props.disableClear) {
                    onChange();
                    props.withOther && onChangeOther();
                }
            }
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        const { meta, label, required, hint, comment, boldLabel } = props;
        const error = meta.invalid && meta.submitFailed ? meta.error : null;

        return <div className={cx("question", { 'question--with-error': !!error, 'question--required': required })}>
                { label &&
                    <div className="question__title">
                        <div className="question__title-wrapper">
                            <div className="question__name">
                                <span className="question-html-title on">{ boldLabel ? <strong>{ label }</strong> : label }</span>
                                { required && <span className="question__required"> *</span>}
                            </div>
                        </div>
                    </div>
                }
                { comment &&
                    <div className="question__comment">
                        <div className="survey-html-comment">
                            <p><span style={{ fontSize: 16 }}>{ comment }</span></p>
                        </div>
                    </div>
                }
                { hint &&
                    <div className="question__hint">
                        <p className="small text-gray">{ hint }</p>
                    </div>
                }
                <WrappedComponent
                    {...props}
                    onChange={onChange}
                    onChangeOther={onChangeOther}
                    otherValue={path(['values', `${props.input.name}_other`], formState)}
                />
                { error && (
                    <div className="question__error-message">{ error }</div>
                )}
            </div>
    }

    return FieldWrapper;
}

export default withFieldWrapper;
