export const COUNTRIES = [
    'Беларусь',
    'Казахстан',
    'Россия',
    'Узбекистан'
];

export const GENDER = [
    'мужской',
    'женский',
];

export const AGE = [
    'менее 18 лет',
    '18-24 лет',
    '25-34 лет',
    '35-44 лет',
    '45-54 лет',
    '55-64 лет',
    '65 лет и более',
];

export const EDUCATION = [
    'Основное общее (9 классов школы)',
    'Среднее общее (11 классов школы)',
    'Среднее профессиональное',
    'Высшее (бакалавриат, специалитет, магистратура)',
];

export const EMPLOYMENT = [
    'Работаю',
    'Работаю и учусь',
    'Учусь и не работаю',
    'Не работаю',
];

export const ORGANIZATION_TYPES = [
    'Металлургия',
    'Машиностроение',
    'Пищевая промышленность',
    'Легкая промышленность (текстильная, швейная, кожевенная, меховая, обувная)',
    'Медицина и фармацевтика',
    'IT',
    'Финансы, банки, инвестиции, страхование',
    'Добыча',
    'ЖКХ',
    'Госслужба',
    'Наука и образование',
    'Логистика',
    'Сельское хозяйство',
    'Строительство и недвижимость',
    'Химическое производство',
    'Розничная торговля',
    'Маркетинг и СМИ',
    'Индустрия развлечения (блогеры, стримеры, шоу-бизнес и пр.)',
    'Туризм и гостиничный бизнес',
    'Военная служба',
    'Свой бизнес',
];

export const EDUCATION_TYPES = [
    'Математические и естественные науки',
    'Инженерное дело, технологии и технические науки',
    'Здравоохранение и медицинские науки',
    'Сельское хозяйство и сельскохозяйственные науки',
    'Науки об обществе',
    'Образование и педагогические науки',
    'Гуманитарные науки',
    'Искусство и культура',
];

export const POSITION_TYPES = [
    'Руководитель организации',
    'Руководитель отдела',
    'Ведущий специалист',
    'Специалист',
    'Рабочий',
    'Технический/вспомогательный персонал',
    'Служащий без высшего образования',
];

export const REGIONS = [
    'город Москва',
    'город Санкт-Петербург',
    'Республика Адыгея',
    'Республика Алтай',
    'Республика Башкортостан',
    'Республика Бурятия',
    'Республика Дагестан',
    'Республика Ингушетия',
    'Кабардино-Балкарская Республика',
    'Республика Калмыкия',
    'Карачаево-Черкесская Республика',
    'Республика Карелия',
    'Республика Коми',
    'Республика Марий Эл',
    'Республика Мордовия',
    'Республика Саха (Якутия)',
    'Республика Северная Осетия — Алания',
    'Республика Татарстан',
    'Республика Тыва',
    'Удмуртская Республика',
    'Республика Хакасия',
    'Чеченская Республика',
    'Чувашская Республика — Чувашия',
    'Алтайский край',
    'Забайкальский край',
    'Камчатский край',
    'Краснодарский край',
    'Красноярский край',
    'Пермский край',
    'Приморский край',
    'Ставропольский край',
    'Хабаровский край',
    'Амурская область',
    'Архангельская область',
    'Астраханская область',
    'Белгородская область',
    'Брянская область',
    'Владимирская область',
    'Волгоградская область',
    'Вологодская область',
    'Воронежская область',
    'Ивановская область',
    'Иркутская область',
    'Калининградская область',
    'Калужская область',
    'Кемеровская область — Кузбасс',
    'Кировская область',
    'Костромская область',
    'Курганская область',
    'Курская область',
    'Ленинградская область',
    'Липецкая область',
    'Магаданская область',
    'Московская область',
    'Мурманская область',
    'Нижегородская область',
    'Новгородская область',
    'Новосибирская область',
    'Омская область',
    'Оренбургская область',
    'Орловская область',
    'Пензенская область',
    'Псковская область',
    'Ростовская область',
    'Рязанская область',
    'Самарская область',
    'Саратовская область',
    'Сахалинская область',
    'Свердловская область',
    'Смоленская область',
    'Тамбовская область',
    'Тверская область',
    'Томская область',
    'Тульская область',
    'Тюменская область',
    'Ульяновская область',
    'Челябинская область',
    'Ярославская область',
    'Ненецкий АО',
    'Ханты-Мансийский АО',
    'Чукотский АО',
    'Ямало-Ненецкий АО',
];

export const CITIES = [
    'Астрахань',
    'Барнаул',
    'Белгород',
    'Брянск',
    'Великий Новгород',
    'Владивосток',
    'Владимир',
    'Волгоград',
    'Волжский',
    'Вологда',
    'Воронеж',
    'Горно-Алтайск',
    'Екатеринбург',
    'Иваново',
    'Ижевск',
    'Иркутск',
    'Йошкар-Ола',
    'Казань',
    'Калининград',
    'Калуга',
    'Кемерово',
    'Киров',
    'Кострома',
    'Краснодар',
    'Красноярск',
    'Курган',
    'Курск',
    'Липецк',
    'Магадан',
    'Магнитогорск',
    'Майкоп',
    'Мурманск',
    'Набережные челны',
    'Нижневартовск',
    'Нижнекамск',
    'Нижний Тагил',
    'Нижний-Новгород',
    'Новокузнецк',
    'Новосибирск',
    'Омск',
    'Оренбург',
    'Орел',
    'Пенза',
    'Пермь',
    'Петрозаводск',
    'Псков',
    'Ростов-на-Дону',
    'Рязань',
    'Самара',
    'Саранск',
    'Саратов',
    'Смоленск',
    'Сочи',
    'Ставрополь',
    'Сургут',
    'Таганрог',
    'Тамбов',
    'Тверь',
    'Тольятти',
    'Томск',
    'Тула',
    'Тюмень',
    'Улан-Удэ',
    'Ульяновск',
    'Уфа',
    'Хабаровск',
    'Чебоксары',
    'Челябинск',
    'Череповец',
    'Чита',
    'Южно-Сахалинск',
    'Якутск',
    'Ярославль',
];
