import cx from 'classnames';

import withFieldWrapper from "./withFieldWrapper";

const Checkbox = ({ input, onChange, text }) => {
    const onChangeValue = e => onChange(e.target.checked ? true : null);

    return <div className="question__wrapper">
        <div className="question__panel">
            <div className="question-list">
                <label className={cx("question-list__item", { active: !!input.value })}>
                    <input type="checkbox" checked={!!input.value} onChange={onChangeValue} />
                    <span className="question-list__item-text">{ text }</span>
                </label>
            </div>
        </div>
    </div>;
}

export default withFieldWrapper(Checkbox);
