import ReactSelect from 'react-select';
import withFieldWrapper from './withFieldWrapper';
import { OTHER_VALUE } from '../../yup';

const Select = ({ withOther, input, onChange, placeholder = 'Выбрать', options = [], onChangeOther, otherValue }) => {
    const getOptions = () => {
        const items = options.map(value => ({ value, label: value }));
        return withOther ? [...items, { value: OTHER_VALUE, label: OTHER_VALUE }] : items;
    };

    const onChangeValue = ({ value }) => {
        if (value !== OTHER_VALUE) {
            onChangeOther(null);
        }

        onChange(value);
    }

    const onChangeOtherValue = e => onChangeOther(e.target.value);

    return <div className="question__wrapper">
        <ReactSelect
            className="react-select-container"
            classNamePrefix="react-select"
            options={getOptions()}
            value={input.value ? { value: input.value, label: input.value } : null}
            onChange={onChangeValue}
            placeholder={placeholder} />
        { withOther && input.value === OTHER_VALUE &&
            <div className="question__other collapse in">
                <p className="question__hint">Напишите ваш вариант ответа</p>
                <textarea
                    value={otherValue || ''}
                    onChange={onChangeOtherValue}
                    cols={10}
                    rows={3}
                    className="form-control"
                    maxLength={2000} />
            </div>
        }
    </div>;
}

export default withFieldWrapper(Select);
